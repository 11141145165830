<template>
    <div>
        <div class="form-table">
        <el-card class="card-pub-notice card-ext-center-title">
            <div slot="header" class="notice_title">
                <span>{{'申请许可'}}</span>
            </div>
            <div>
                <el-form :model="dataForm" :rules="dataRule" label-width="120px">
                    <div class="ulbox">
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item :label="'投产开始时间'" prop="allowCutBeginTime">
                                    <el-date-picker placeholder="选择日期"
                                                    v-model="dataForm.allowCutBeginTime"
                                                    type="datetime"
                                                    style="width: 100%;"
                                                    value-format="yyyy-MM-dd HH:mm"
                                                    format="yyyy-MM-dd HH:mm"
                                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'受令人'" prop="allowOrderedPerson">
                                    <el-input v-model="dataForm.allowOrderedPerson" placeholder="请填写受令人姓名"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'调度员'" prop="allowDispatchBegin">
                                    <el-input v-model="dataForm.allowDispatcherBegin" placeholder="请填写调度员姓名"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6"></el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <p style="color: #404040;font-size: 15px;">申请单位所列的工作要求的安全措施已布置完成</p>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item :label="'许可方式'">
                                    <el-input readonly v-model="baseInfo.permissionTypeName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'工作许可人'" prop="allowLicensor">
                                    <el-input v-model="dataForm.allowLicensor" placeholder="请填写工作许可人姓名"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'调度许可人'" prop="allowDispatchBegin">
                                    <el-input v-model="dataForm.allowDispatcher" placeholder="请填写调度许可人姓名"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'许可时间'" prop="allowTime">
                                    <el-date-picker
                                        v-model="dataForm.allowTime"
                                        type="datetime"
                                        placeholder="选择日期时间"
                                        style="width: 100%;"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </el-form>
            </div>
        </el-card>
        <el-card class="card-pub-notice card-ext-center-title">
            <div slot="header" class="notice_title">
                <span>{{'申请变更'}}</span>
            </div>
            <div>
                <el-form :model="dataForm" :rules="dataRule" label-width="120px">
                    <div class="ulbox">
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <p style="font-size: 14px; color: #606266">不需变更工作要求的安全措施下增加的工作内容</p>
                                <el-form-item prop="changeContent">
                                    <el-input type="textarea" :rows="2" v-model="dataForm.changeContent" placeholder="请填写不需变更工作要求的安全措施下增加的工作内容"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item :label="'许可方式'" prop="changePermissionType">
                                    <el-select v-model="dataForm.changePermissionType" placeholder="请选择许可方式">
                                        <el-option v-for="item in keyValues.permissionTypeList"
                                                   :key="item.itemKey"
                                                   :value="item.itemKey"
                                                   :label="item.itemValue"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'工作许可人'" prop="changeLicensor">
                                    <el-input v-model="dataForm.changeLicensor" placeholder="请填写工作许可人姓名"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'调度许可人'" prop="changeContent">
                                    <el-input v-model="dataForm.changeDispatcher" placeholder="请填写调度许可人姓名"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'许可时间'" prop="changeAllowTime">
                                    <el-date-picker
                                        v-model="dataForm.changeAllowTime"
                                        type="datetime"
                                        placeholder="选择日期时间"
                                        style="width: 100%;"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </el-form>
            </div>
        </el-card>
        <el-card class="card-pub-notice card-ext-center-title">
            <div slot="header" class="notice_title">
                <span>{{'延期申请'}}</span>
            </div>
            <div>
                <el-form :model="dataForm" :rules="dataRule" label-width="120px">
                    <div class="ulbox">
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <el-form-item :label="'延期原因'" prop="delayReason">
                                    <el-input type="textarea" :rows="3" v-model="dataForm.delayReason" placeholder="请填写延期原因"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item :label="'延期后工作时间'" prop="delayWorkTime">
                                    <el-date-picker type="datetime" placeholder="选择日期"
                                                    v-model="dataForm.delayWorkTime"
                                                    style="width: 100%;"
                                                    format="yyyy-MM-dd HH:mm"
                                                    value-format="yyyy-MM-dd HH:mm">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'工作签发人'" prop="delayIssuer">
                                    <el-input v-model="dataForm.delayIssuer" placeholder="请填写工作签发人"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6"></el-col>
                            <el-col :span="6"></el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item :label="'许可方式'" prop="delayPermissionType">
                                    <el-select v-model="dataForm.delayPermissionType" placeholder="请选择许可方式">
                                        <el-option v-for="item in keyValues.permissionTypeList"
                                                   :key="item.itemKey"
                                                   :value="item.itemKey"
                                                   :label="item.itemValue"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'工作许可人'" prop="delayLicensor">
                                    <el-input v-model="dataForm.delayLicensor" placeholder="请填写工作许可人姓名"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'调度许可人'" prop="delayDispatcher">
                                    <el-input v-model="dataForm.delayDispatcher" placeholder="请填写调度许可人姓名"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'许可时间'" prop="delayAllowTime">
                                    <el-date-picker
                                        v-model="dataForm.delayAllowTime"
                                        type="datetime"
                                        placeholder="选择日期时间"
                                        style="width: 100%;"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </el-form>
            </div>
        </el-card>
        <el-card class="card-pub-notice card-ext-center-title">
            <div slot="header" class="notice_title">
                <span>{{'申请单的终结'}}</span>
            </div>
            <div>
                <el-form :model="dataForm" :rules="dataRule" label-width="120px">
                    <div class="ulbox">
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item :label="'许可方式'" prop="changeContent">
                                    <el-input readonly v-model="baseInfo.permissionTypeName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'工作许可人'" prop="finishLicensor">
                                    <el-input v-model="dataForm.finishLicensor" placeholder="请填写工作许可人姓名"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'调度许可人'" prop="finishDispatcher">
                                    <el-input v-model="dataForm.finishDispatcher" placeholder="请填写调度许可人姓名"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'许可时间'" prop="finishAllowTime">
                                    <el-date-picker
                                        v-model="dataForm.finishAllowTime"
                                        type="datetime"
                                        placeholder="选择日期时间"
                                        style="width: 100%;"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item :label="'投产结束时间'" prop="finishCutBeginTime">
                                    <el-date-picker type="datetime" placeholder="选择日期"
                                                    v-model="dataForm.finishCutBeginTime"
                                                    style="width: 100%;"
                                                    format="yyyy-MM-dd HH:mm"
                                                    value-format="yyyy-MM-dd HH:mm">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'受令人'" prop="finishOrderedPerson">
                                    <el-input v-model="dataForm.finishOrderedPerson" placeholder="请填写受令人姓名"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'调度员'" prop="finishAllowTime">
                                    <el-input v-model="dataForm.finishDispatcherBegin" placeholder="请填写调度员姓名"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6"></el-col>
                        </el-row>

                    </div>
                </el-form>
            </div>
        </el-card>
        <el-card class="card-pub-notice card-ext-center-title">
            <div slot="header" class="notice_title">
                <span>{{'调度备注'}}</span>
            </div>
            <div>
                <el-form :model="dataForm" :rules="dataRule" label-width="120px">
                    <div class="ulbox">
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <el-form-item :label="'调度备注'" prop="remark">
                                    <el-input type="textarea" :rows="3" v-model="dataForm.remark" placeholder="请填写调度备注"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </el-form>
            </div>
        </el-card>
        </div>
        <div class="submit-btn-group button-fixed">
            <el-button @click="closeForm">关闭</el-button>
            <el-button @click="saveApply('0')" type="primary">保存</el-button>
            <el-button @click="saveApply('1')" type="primary">保存并发布</el-button>
            <el-button type="danger" @click ="ratifyCancellation()">作废</el-button>
        </div>
    </div>
</template>

<script>
import {previewPdf} from '@/utils';
export default {
    data() {
        return {
            baseInfo: {
                permissionType: '', //许可方式
                permissionTypeName: '', //许可方式名称
                workflowTaskId: '',
            },
            definitionCode: '',
            nodeCode: '',
            instanceId: '',
            dataForm: {
                id: '',
                overhaulId: '',
                applyId: '',
                allowCutBeginTime: '',
                allowCutEndTime: '',
                allowOrderedPerson: '',
                allowReplyOrderPerson: '',
                allowDispatcherBegin: '',
                allowDispatcherEnd: '',
                allowLicensor: '',
                allowDispatcher: '',
                allowTime: '',
                allowPermissionType: '',
                changeContent: '',
                changePermissionType: '',
                changeLicensor: '',
                changeDispatcher: '',
                changeAllowTime: '',
                delayReason: '',
                delayWorkTime: '',
                delayIssuer: '',
                delayPermissionType: '',
                delayLicensor: '',
                delayDispatcher: '',
                delayAllowTime: '',
                finishPermissionType: '',
                finishLicensor: '',
                finishDispatcher: '',
                finishAllowTime: '',
                finishCutBeginTime: '',
                finishCutEndTime: '',
                finishOrderedPerson: '',
                finishReplyOrderPerson: '',
                finishDispatcherBegin: '',
                finishDispatcherEnd: '',
                remark: '',
            },
            keyValues: {
                permissionTypeList: [], //申请变更许可类型集合
            },
            dataRule: {}
        };
    },
    methods: {
        ratifyCancellation() {
            const data = Object.assign({}, this.dataForm);
            this.$confirm('确定同意作废该项工作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$client.workflowInstanceProcess({
                    definitionCode: 'elec_overhaul_planned',
                    instanceId: this.instanceId,
                    nodeCode: this.nodeCode,
                    operationType: 'halt',
                    data,
                }).then(() => {
                    this.$message({
                        message: '操作成功',
                        type: 'success'
                    });
                    this.closeForm(2);
                });
            });
        },
        saveApply(formState) {
            const data = Object.assign({}, this.dataForm);
            data.workflowTaskId = this.baseInfo.workflowTaskId;
            data.formState = formState ? formState : '0';
            this.$client.workflowInstanceProcess({
                definitionCode: this.definitionCode,
                instanceId: this.instanceId,
                nodeCode: this.nodeCode,
                operationType: formState === '0' ? 'save' : 'saveAndNext',
                data,
                user: this.$store.state.userInfo.username,
                roles: this.$store.state.userRole
            }).then(resp => {
                if (resp.code === 0) {
                    this.closeForm(1);
                }
            });
        },
        init(row) {
            if (row.record_receipt) {
                this.dataForm = row.record_receipt.data;
            }
            this.instanceId = row.id;
            this.definitionCode = row.definitionCode;
            this.nodeCode = row.nextNode.code;
            this.dataForm.overhaulId = row.businessFormData.id;
            this.dataForm.applyId = row.businessFormData.applyId;
            this.baseInfo.permissionType = row.record_apply.data.permissionType;
            this.baseInfo.workflowTaskId = row.id;
            this.dataForm.allowPermissionType = row.businessFormData.permissionType;
            this.dataForm.finishPermissionType = row.businessFormData.permissionType;
            this.initFormValue();
        },
        closeForm(type) {
            this.$emit('closeForm', type);
        },
        async bindKeyValues() {
            const applyId = this.dataForm.applyId;
            const data = await Promise.all([
                this.$client.getDicData('overhaul_permission_type'),
            ]);
            //匹配不可变更的许可方式
            let pList = data[0].data || [];
            if (pList.length > 0) {
                for (let i = 0; i < pList.length; i++) {
                    if (this.baseInfo.permissionType === pList[i].itemKey) {
                        this.baseInfo.permissionTypeName = pList[i].itemValue;
                        break;
                    }
                }
            }
            //许可方式select
            this.keyValues.permissionTypeList = data[0].data || [];
            // //初始化dataForm
            // let dataForm = data[1].receive || this.dataForm;
            // dataForm.applyId = dataForm.applyId || this.dataForm.applyId;
            // dataForm.overhaulId = dataForm.overhaulId || this.dataForm.overhaulId;
            // this.dataForm = dataForm;
        },
        async initFormValue() {
            await this.bindKeyValues();
        }
    }
};
</script>
<style lang="less" scoped="">
    /deep/ .el-textarea__inner {
        color: white;
    }
    .button-fixed {
        margin:  0 !important;
    }
    .submit-btn-group {
        text-align: center;
        margin-top: 60px;
        margin-bottom: 60px;
    }
    .form-table {
        height: calc(100vh - 170px);
        margin-bottom: 10px;
        overflow-y: scroll;
    }
</style>
