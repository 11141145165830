<template>
    <div>
    <div class="form-table">
        <el-card class="card-pub-notice card-ext-center-title">
            <div slot="header" class="notice_title">
                <span>{{ '投产申请' }}</span>
            </div>
            <div>
                <el-form :model="dataForm" :rules="dataRule" label-width="120px" ref="overhaulForm">
                    <div class="ulbox">
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item :label="'执行调度'">
                                    <el-input v-model="dataForm.dispatchUnit "  type="textarea">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'申请单编号'" prop="ticketNo">
                                    <el-input v-model="dataForm.ticketNo" placeholder="请输入申请单编号">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'申请单位'" >
                                    <el-input v-model="dataForm.applyUnit" placeholder="请输入申请单位">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'申请填报人'">
                                    <el-input v-model="dataForm.applyBy" placeholder="请输入请输入申请填报人">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item :label="'申请填报人联系电话'">
                                    <el-input v-model="dataForm.applyPN" placeholder="请输入请输入申请填报人联系电话">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'检修类别'" placeholder="请输入请选择检修类别">
                                    <!--<el-input v-model="dataForm.overhaulType ">
                                    </el-input>-->
                                    <el-select v-model="dataForm.overhaulType">
                                        <el-option v-for="item in this.overhaulTypes"
                                                   :key="item.name"
                                                   :label="item.name"
                                                   :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>


                            <el-col :span="6">
                                <el-form-item :label="'许可方式'" prop="permissionType">
                                    <el-select v-model="dataForm.permissionType" placeholder="请选择许可方式">
                                        <el-option
                                            v-for="item in keyValues.permissionTypeList"
                                            :key="item.itemKey"
                                            :value="item.itemKey"
                                            :label="item.itemValue"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'工作许可人'" prop="licensorId">
                                    <el-input v-model="dataForm.licensorId" placeholder="请输入许可人"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>


                        <el-row :gutter="24">

                            <el-col :span="6">
                                <el-form-item :label="'工作许可人联系电话'">
                                    <el-input v-model="dataForm.licensorMobile" placeholder="请输入许可人联系电话">
                                    </el-input>
                                </el-form-item>
                            </el-col>


                            <el-col :span="6">
                                <el-form-item :label="'工作负责人'" prop="chargeName">
                                    <el-input v-model="dataForm.chargeName" placeholder="请输入工作负责人"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'工作负责人联系电话'" prop="chargeTel" >
                                    <el-input
                                        v-model="dataForm.chargeTel"
                                        placeholder="请输入负责人联系电话"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <el-form-item :label="'工作地点'" prop="workAddress">
                                    <el-input v-model="dataForm.workAddress"
                                              placeholder="请输入工作地点"
                                              type="textarea"
                                              :rows="2">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="2">
                                <el-form-item :label="'工作附件'"></el-form-item>
                            </el-col>
                            <el-col :span="22">
                                <el-form :inline="true">
                                    <el-form-item>
                                        <el-upload
                                            :headers="uploadHeaders"
                                            :show-file-list="false"
                                            :action="uploadUrl"
                                            drag
                                            multiple
                                            accept="application/doc"
                                            :before-upload="beforeUpload"
                                            :on-success="uploadCallback1"
                                            :auto-upload="true"
                                        >
                                            <i class="el-icon-upload"></i>
                                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                        </el-upload>
                                    </el-form-item>
                                    <el-form-item v-for="(item, index) in drawing10" :key="item.ossId">
                                        <div style="position: relative">
                                            <el-image
                                                @click="showPdf(item)"
                                                style="width: 300px; height: 200px"
                                                :src="createImage(item)"
                                                fit="contain"
                                            ></el-image>
                                            <!--createImage(item)-->
                                            <i
                                                class="el-icon-remove-outline"
                                                @click="handleRemove(drawing10, index)"
                                                style="
                                                    cursor: pointer;
                                                    position: absolute;
                                                    right: 10px;
                                                    top: 5px;
                                                    font-size: 24px;
                                                    color: #e50000;
                                                "
                                            ></i>
                                            <div style="text-align: center">{{ item.name }}</div>
                                        </div>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <el-form-item :label="'申请投产设备'" prop="equipmentName">
                                    <el-input v-model="dataForm.equipmentName"
                                              placeholder="申请投产设备"
                                              type="textarea"
                                              :row="2"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="18">
                                <p style="font-size: 5px; color: #8c939d">
                                    已选中的设备自动移出下拉选择框。删除某设备标签后，该设备默认添加至下拉选择框的末尾
                                </p>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="4" v-for="(item, i) in this.dataForm.equipmentList" :key="i">
                                <el-form-item>
                                    <el-tag
                                        :key="item.equipmentName"
                                        :closable="true"
                                        @close="closeTag(item.equipmentCode)"
                                    >
                                        {{ item.equipmentName }}
                                    </el-tag>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <el-form-item :label="'工作内容'">
                                    <el-input v-model="dataForm.workContent"
                                              placeholder="请输入工作内容"
                                              type="textarea"
                                              :rows="2">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24" style="white-space: nowrap;">
                            <el-col :span="24">
                                <el-form-item :label="'一次设备状态要求'"  type="textarea">
                                    <el-input type="textarea" v-model="dataForm.firstRequire" placeholder="请输入一次设备状态要求"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24" style="white-space: nowrap;">
                            <el-col :span="24">
                                <el-form-item :label="'二次设备状态要求'"  type="textarea">
                                    <el-input type="textarea" v-model="dataForm.secondRequire" placeholder="请输入二次设备状态要求"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="6" >
                                <el-form-item :label="'投产开始时间'" prop="workBeginTime">
                                    <el-date-picker
                                        v-model="dataForm.workBeginTime"
                                        type="datetime"
                                        placeholder="选择日期"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <!--<el-col :span="6">
                                <el-form-item :label="'申请工作结束时间'" prop="workEndTime">
                                    <el-date-picker
                                        v-model="dataForm.workEndTime"
                                        type="datetime"
                                        placeholder="选择日期"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'申请停电操作开始时间'" prop="cutBeginTime">
                                    <el-date-picker
                                        v-model="dataForm.cutBeginTime"
                                        type="datetime"
                                        placeholder="选择日期"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'申请复电操作开始时间'" prop="cutEndTime">
                                    <el-date-picker
                                        v-model="dataForm.cutEndTime"
                                        type="datetime"
                                        placeholder="选择日期"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>-->
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="18">
                                <el-form-item :label="'工作对系统的影响'">
                                    <el-input style="width: 137.5%"
                                              type="textarea"
                                              :rows="2"
                                              v-model="dataForm.securityEffect"
                                              placeholder="请输入工作对系统的影响"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item></el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <el-form-item :label="'工作对供电可靠性的影响'">
                                    <el-input
                                        type="textarea"
                                        :rows="2"
                                        v-model="dataForm.reliabilityEffect"
                                        placeholder="请输入工作对供电可靠性的影响"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item></el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <el-form-item :label="'其他要求'">
                                    <el-input
                                        type="textarea"
                                        :rows="2"
                                        v-model="dataForm.otherRequire"
                                        placeholder="请输入其他要求"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item></el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <el-form-item :label="'申请备注'">
                                    <el-input
                                        type="textarea"
                                        :rows="2"
                                        v-model="dataForm.remark"
                                        placeholder="请输入备注"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item></el-form-item>
                            </el-col>
                        </el-row>

                    </div>
                </el-form>

                <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
            </div>
        </el-card>
    </div>
    <div class="submit-btn-group button-fixed">
        <el-button @click="closeForm">关闭</el-button>
        <el-button @click="saveApply('0')" type="primary">保存</el-button>
        <el-button @click="saveApply('1')" type="primary">保存并发布</el-button>
    </div>
    </div>
</template>


<script>
//import {previewPdf,} from '@/utils';
import {previewPdf, fileDownload} from '@/utils';
import * as dayjs from 'dayjs';
import preview from '@/assets/images/preview.png';
export default {
    data() {
        return {
            submissionTime: '', //提交时间
            uploadHeaders: {
                token: this.$store.state.token,
            },
            uploadUrl: this.axios.defaults.baseURL + '/files',
            activeName: '1',
            baseServer: this.axios.defaults.baseURL,
            instanceId: '',
            definitionCode: '',
            nodeId: '',
            nodeCode: '',
            overhaulTypes: [
                {
                    name: '计划检修',
                    value: '1',
                },
                {
                    name: '新设备投产',
                    value: '2',
                },

            ],
            roleList: [],
            dataForm: {
                id: 0,
                workflowId: 0,
                drawingNum: '',
                drawingStatus: '',
                dispatchUnit: '云南滇中新区配售电有限公司发计部', //执行调度单位
                ticketNo: '', //申请单编号
                applyUnit: '', //申请单位
                createName: '', //申请填报人
                createPhone: '', //申请填报人联系电话
                applyId: '', //申请单号
                overhaulType: '', //检修类别
                type: 1, //类型 1-计划内检修 2-非计划检修
                permissionType: '', //许可方式
                licensorId: '', //许可人id
                chargeName: '', //负责人姓名
                chargeTel: '', //负责人联系方式
                workAddress: '', //工作地点
                workContent: '', //工作内容
                equipmentId: '', //设备id，逗号隔开
                equipmentName: '', //设备名称
                firstRequire: '', //第一次设备状态要求
                secondRequire: '', //第二次设备状态要求
                workBeginTime: '', //工作开始时间
                workEndTime: '', //工作结束时间
                cutBeginTime: '', //停电开始时间
                cutEndTime: '', //停电结束时间
                securityEffect: '', //涉及电网安全运行的影响
                reliabilityEffect: '', //涉及供电可靠性的影响
                otherRequire: '', //其他要求
                remark: '', //备注
                attachList: [], //附件集合
                equipmentList: [], //设备集合
            },
            drawing10: [],
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            dataRule: {
                ticketNo: [
                    {
                        required: true,
                        message: '申请单编号' + ' ' + '必填',
                        trigger: 'blur',
                    }
                ],
                permissionType: [
                    {
                        required: true,
                        message: '许可类型' + ' ' + '必填',
                        trigger: 'blur',
                    },
                ],
                licensorId: [
                    {
                        required: true,
                        message: '许可人' + ' ' + '必填',
                        trigger: 'blur',
                    },
                ],
                chargeName: [
                    {
                        required: true,
                        message: '工作负责人' + ' ' + '必填',
                        trigger: 'blur',
                    },
                ],
                chargeTel: [
                    {
                        required: true,
                        message: '工作负责人联系电话' + ' ' + '必填',
                        trigger: 'blur',
                    },
                ],
                workAddress: [
                    {
                        required: true,
                        message: '工作地点' + ' ' + '必填',
                        trigger: 'blur',
                    },
                ],
                equipmentName: [
                    {
                        required: true,
                        message: '停电/监测设备' + ' ' + '必填',
                        trigger: 'blur',
                    },
                ],
                workBeginTime: [
                    {
                        required: true,
                        message: '工作开始时间' + ' ' + '必填且必须大于当前时间两天',
                        trigger: 'blur',
                    },
                ],
                workEndTime: [
                    {
                        required: true,
                        message: '工作结束时间' + ' ' + '必填且必须大于工作开始时间',
                        trigger: 'blur',
                    },
                ],
                cutBeginTime: [
                    {
                        required: true,
                        message: '停电开始时间' + ' ' + '必填',
                        trigger: 'blur',
                    },
                ],
                cutEndTime: [
                    {
                        required: true,
                        message: '停电结束时间' + ' ' + '必填且必须大于停电开始时间',
                        trigger: 'blur',
                    },
                ],
            },
            uploadLoading: null,
            keyValues: {
                permissionTypeList: [], //许可类型集合
                sysUserList: [], //用户集合原始数据
                allEquipment: [], //所有设备
            },
        };
    },
    methods: {
        createImage(item) {
            if (item.ext === 'pdf') {
                let src = this.uploadUrl + item.thumbUrl;
                return src;
            }
            return preview;
        },
        beforeUpload(file) {
            const isPDF = file.type.indexOf('pdf') > -1 || file.type.indexOf('msword') > -1 || file.type.indexOf('vnd.openxmlformats-officedocument.wordprocessingml.document') > -1;
            const isLt2M = file.size / 1024 / 1024 < 20;

            if (!isPDF) {
                this.$message.error('只能上传PDF或者doc格式图纸!');
                return false;
            }
            if (!isLt2M) {
                this.$message.error('图片大小不能超过20MB!');
                return false;
            }
            this.uploadLoading = this.$loading({
                lock: true,
                text: '请稍候',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
            });
        },
        exportDoc(item) {
            fileDownload(`/files/${item.id}`, {method: 'get'});
        },
        handleRemove(obj, index) {
            obj.splice(index, 1);
        },
        showPdf(item) {
            if (item.ext === 'pdf') {
                previewPdf(this.baseServer + '/files/' + item.id + '?token=' + this.uploadHeaders.token);
            } else {
                this.exportDoc(item);
            }
        },
        handelResData(res) {
            const data = res.data;
            data.thumbUrl = '/' + data.id + '/thumbnail?token=' + this.uploadHeaders.token;
            return data;
        },
        handelResData2(list) {
            for (let item of list) {
                item.thumbUrl = '/' + item.id + '/thumbnail?token=' + this.uploadHeaders.token;
            }
        },
        uploadCallback1(res) {
            this.uploadLoading.close();
            this.drawing10.push(this.handelResData(res));
        },
        validate(form) {
            //其余参数校验
            let validate = false;
            if (!form) {
                return true;
            }
            form.validate((valid) => {
                if (valid) {
                    validate = true;
                } else {
                    validate = false;
                }
            });
            return validate;
        },
        saveApply (formState) {
            let nextRoles = [];
            let userRoleIds = this.$store.state.userInfo.roleCodeList;
            if (formState === '0' && this.roleList) {
                //设置下环节
                userRoleIds.forEach(temp => {
                    this.roleList.forEach(item => {
                        if (temp === 'SCBSQTBR' && item.roleCode === temp) {
                            nextRoles.push(item.roleId);
                        }
                        if (temp === 'FJBSQTBR' && item.roleCode === temp) {
                            nextRoles.push(item.roleId);
                        }
                        if (temp === 'GCGLBSQTBR' && item.roleCode === temp) {
                            nextRoles.push(item.roleId);
                        }
                    });
                });
            }
            if (formState === '1' && this.roleList) {
                //设置下环节
                userRoleIds.forEach(temp => {
                    this.roleList.forEach(item => {
                        if (temp === 'SCBSQTBR' && item.roleCode === 'SCBXMJBR') {
                            nextRoles.push(item.roleId);
                        }
                        if (temp === 'FJBSQTBR' && item.roleCode === 'FJBXMJBR') {
                            nextRoles.push(item.roleId);
                        }
                        if (temp === 'GCGLBSQTBR' && item.roleCode === 'GCGLBXMJBR') {
                            nextRoles.push(item.roleId);
                        }
                    });
                });
            }

            if (this.dataForm.workBeginTime && !this.submissionTime) {
                let s = new Date(dayjs().add(2, 'day')).setHours(0, 0, 0, 0);
                let c = dayjs(s); //.format('YYYY-MM-DD HH:mm:ss')
                let b = dayjs(this.dataForm.workBeginTime).isAfter(c);
                if (!b) {
                    this.$message({
                        message: '投产开始时间必须大于当前时间两天以上',
                        type: 'error',
                    });
                    return false;
                }
            }
            if (this.dataForm.workBeginTime && this.submissionTime) {
                let s = new Date(dayjs(this.submissionTime).add(2, 'day')).setHours(0, 0, 0, 0);
                let c = dayjs(s); //.format('YYYY-MM-DD HH:mm:ss')
                let b = dayjs(this.dataForm.workBeginTime).isAfter(c);
                if (!b) {
                    this.$message({
                        message: '工作开始时间必须大于提交时间两天以上',
                        type: 'error',
                    });
                    return false;
                }
            }
            //封装表单数据
            const data = Object.assign({}, this.dataForm);
            //附件集合
            data.attachList = this.drawing10;
            data.formState = formState ? formState : '0';
            // 生成任务号
            //const ticketNo = `JX${this.$dayjs().format('YYYYMMDD')}${Math.ceil(Math.random() * 100000).toString().padStart(5, '0')}`;
            //data.ticketNo = ticketNo;
            //校验
            //const valid = this.validate(this.$refs.overhaulForm);
            this.$refs.overhaulForm.validate((valid) => {
                if (valid) {
                    this.$confirm('确认保存？', '提示', {
                        confirmButtonClass: 'el-button el-button--mini el-button--primary',
                        type: 'warning',
                    }).then(() => {
                        this.$client.workflowInstanceProcess({
                            definitionCode: this.definitionCode,
                            instanceId: this.instanceId,
                            nodeCode: this.nodeCode,
                            operationType: formState === '0' ? 'save' : 'saveAndNext',
                            data,
                            user: this.$store.state.userInfo.username,
                            roles: this.$store.state.userRole,
                            nextRoles,
                            extraProp: {
                                /*ticketNo: this.ticketNo,*/
                                createUser: this.$store.state.userInfo.username,
                            }
                        }).then(resp => {
                            if (resp.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success'
                                });
                                this.closeForm(1);
                            } else {
                                this.$message({
                                    message: '操作失败',
                                    type: 'error'
                                });
                            }
                        }).catch(() => {
                            this.$message({
                                message: '操作失败！',
                                type: 'error',
                            });
                        });
                    });
                }
            });
            /*if (valid) {
                    this.$confirm('确认保存？', '提示', {
                        confirmButtonClass: 'el-button el-button--mini el-button--primary',
                        type: 'warning',
                    }).then(() => {*/
        },
        init(row) {
            if (row && row.businessFormData && row.businessFormData.applyId) {
                this.dataForm = row.businessFormData;
            } else {
                this.dataForm.type = row.type;
            }
            this.submissionTime = dayjs(row.startTime).add(8, 'hours').format('YYYY-MM-DD HH:mm:ss');
            this.definitionCode = row.definitionCode;
            this.instanceId = row.id;
            this.nodeId = row.nextNode.id;
            this.nodeCode = row.nextNode.code;
            if (row.record_apply && row.record_apply.data) {
                this.dataForm = row.record_apply.data;
                this.drawing10 = row.record_apply.data.attachList;
            }
            this.initFormValue();
        },
        closeForm(type) {
            this.$emit('closeForm', type);
            this.$root.$emit('elec_production_refresh');
        },
        async bindKeyValues() {
            let orgCodes = ['WB01'];
            //判断当前登录的用户是发计部还是市场部
            let userRoleCode = this.$store.state.userInfo.roleCodeList;
            let unitType = '';
            if (userRoleCode && userRoleCode.indexOf('SCB') > -1) {
                unitType = 'SCB';
            }
            if (userRoleCode && userRoleCode.indexOf('FJB') > -1) {
                unitType = 'FJB';
            }
            if (userRoleCode && userRoleCode.indexOf('GCGLB') > -1) {
                unitType = 'GCGLB';
            }
            let applyId = this.dataForm.applyId;
            const data = await Promise.all([
                this.$client.getDicData('overhaul_permission_type'),
                this.$client.queryUserByOrgCode(orgCodes),
                this.$client.queryAllEquipmentByView(),
                this.$client.queryOverhaulRoleList(unitType),
            ]);
            this.keyValues.permissionTypeList = data[0].data || [];
            //用户集合基础数据
            this.keyValues.sysUserList = data[1].data || [];
            //所有设备集合
            this.keyValues.allEquipment = data[2].data || [];
            //初始化附件
            if (this.drawing10.length > 0) {
                const tocken = this.uploadHeaders.token;
                this.drawing10.forEach(function (item) {
                    item.thumbUrl = '/' + item.id + '/thumbnail?token=' + tocken;
                });
            }
            //角色
            this.roleList = data[3].data;
        },
        async initFormValue() {
            await this.bindKeyValues();
        },
        selectEquipment(vId) {
            if (!vId || !this.keyValues.allEquipment) {
                return;
            }
            let index = -1;
            let genList = this.keyValues.allEquipment;
            let showList = this.dataForm.equipmentList || [];
            //校验，已选择就不能再添加
            if (showList.length) {
                let validate = true;
                for (let i = 0; i < showList.length; i++) {
                    if (showList[i].equipmentCode === vId) {
                        validate = false;
                        break;
                    }
                }
                if (!validate) {
                    this.$message({
                        message: '该设备已被选择',
                        type: 'error',
                    });
                    return;
                }
            }
            for (let i = 0; i < genList.length; i++) {
                if (vId === genList[i].equipmentCode) {
                    showList.push(genList[i]);
                    index = i;
                    break;
                }
            }
            /*if (index > -1) {
                        genList.splice(index, 1);
                    }*/
            return;
        },
        closeTag(id) {
            if (!id) {
                return;
            }
            let index = 0;
            let showList = this.dataForm.equipmentList;
            for (let i = 0; i < showList.length; i++) {
                if (showList[i].equipmentCode === id) {
                    index = i;
                    break;
                }
            }
            /*let node = showList[index];
                    this.keyValues.allEquipment.push(node);*/
            showList.splice(index, 1);
            if (showList.length === 0) {
                this.dataForm.equipmentName = '';
            }
        },
    },
    /* created() {

            alert(c);
        }*/
};
</script>
<style lang="less" scoped="">
    /deep/ .el-textarea__inner {
        color: white;
    }
    .form-table {
        height: calc(100vh - 170px);
        margin-bottom: 10px;
        overflow-y: scroll;
    }
    .button-fixed {
        margin:  0 !important;
    }
    .submit-btn-group {
        text-align: center;
        margin-top: 60px;
        margin-bottom: 60px;
    }

</style>
